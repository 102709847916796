
    <template>
      <section class="content element-doc">
        <h2>Upload 上传</h2>
<p>通过点击或者拖拽上传文件</p>
<h3>点击上传</h3>
<demo-block>
        <div><p>通过 slot 你可以传入自定义的上传按钮类型和文字提示。可通过设置<code>limit</code>和<code>on-exceed</code>来限制上传文件的个数和定义超出限制时的行为。可通过设置<code>before-remove</code>来阻止文件移除操作。</p>
</div>
        <template #source><element-demo0 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  class=&quot;upload-demo&quot;
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  :on-preview=&quot;handlePreview&quot;
  :on-remove=&quot;handleRemove&quot;
  :before-remove=&quot;beforeRemove&quot;
  multiple
  :limit=&quot;3&quot;
  :on-exceed=&quot;handleExceed&quot;
  :file-list=&quot;fileList&quot;
&gt;
  &lt;el-button size=&quot;small&quot; type=&quot;primary&quot;&gt;点击上传&lt;/el-button&gt;
  &lt;template v-slot:tip&gt;
    &lt;div class=&quot;el-upload__tip&quot;&gt;只能上传jpg/png文件，且不超过500kb&lt;/div&gt;
  &lt;/template&gt;
&lt;/el-upload&gt;
&lt;script&gt;
  import { reactive, toRefs, getCurrentInstance } from 'vue'
  export default {
    setup() {
      let state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })
      const self = getCurrentInstance().ctx
      const handleRemove = (file, fileList) =&gt; {
        console.log(file, fileList)
      }
      const handlePreview = (file) =&gt; {
        console.log(file)
      }
      const handleExceed = (files, fileList) =&gt; {
        self.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        )
      }
      const beforeRemove = (file, fileList) =&gt; {
        // return self.$confirm(`确定移除 ${ file.name }？`);
      }

      return {
        ...toRefs(state),
        handleRemove,
        handlePreview,
        handleExceed,
        beforeRemove
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>用户头像上传</h3>
<p>使用 <code>before-upload</code> 限制用户上传的图片格式和大小。</p>
<demo-block>
        
        <template #source><element-demo1 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  class=&quot;avatar-uploader&quot;
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  :show-file-list=&quot;false&quot;
  :on-success=&quot;handleAvatarSuccess&quot;
  :before-upload=&quot;beforeAvatarUpload&quot;
&gt;
  &lt;img v-if=&quot;imageUrl&quot; :src=&quot;imageUrl&quot; class=&quot;avatar&quot; /&gt;
  &lt;i v-else class=&quot;el-icon-plus avatar-uploader-icon&quot;&gt;&lt;/i&gt;
&lt;/el-upload&gt;

&lt;style&gt;
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
&lt;/style&gt;

&lt;script&gt;
  import { ref } from 'vue'
  import { Message } from 'element3'
  export default {
    setup() {
      const imageUrl = ref('')

      const handleAvatarSuccess = (res, file) =&gt; {
        imageUrl.value = URL.createObjectURL(file.raw)
      }
      const beforeAvatarUpload = (file) =&gt; {
        const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 &lt; 2

        if (!isJPG) {
          Message.error('上传头像图片只能是 JPG 格式!')
        }
        if (!isLt2M) {
          Message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG &amp;&amp; isLt2M
      }

      return {
        imageUrl,
        handleAvatarSuccess,
        beforeAvatarUpload
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>照片墙</h3>
<p>使用 <code>list-type</code> 属性来设置文件列表的样式。</p>
<demo-block>
        
        <template #source><element-demo2 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  list-type=&quot;picture-card&quot;
  :on-preview=&quot;handlePictureCardPreview&quot;
  :on-remove=&quot;handleRemove&quot;
&gt;
  &lt;i class=&quot;el-icon-plus&quot;&gt;&lt;/i&gt;
&lt;/el-upload&gt;
&lt;el-dialog :visible.sync=&quot;dialogVisible&quot; v-model:visible=&quot;dialogVisible&quot;&gt;
  &lt;img :src=&quot;dialogImageUrl&quot; alt=&quot;&quot; style=&quot;width: 100%;&quot; /&gt;
&lt;/el-dialog&gt;
&lt;script&gt;
  import { ref, unref } from 'vue'
  export default {
    setup() {
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)

      const handleRemove = (file, fileList) =&gt; {
        console.log(file, fileList)
      }
      const handlePictureCardPreview = (file) =&gt; {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      return {
        dialogImageUrl,
        dialogVisible,
        handleRemove,
        handlePictureCardPreview
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>文件缩略图</h3>
<p>使用 <code>scoped-slot</code> 去设置缩略图模版。</p>
<demo-block>
        
        <template #source><element-demo3 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  list-type=&quot;picture-card&quot;
  ref=&quot;uploadRef&quot;
  :auto-upload=&quot;false&quot;
&gt;
  &lt;template #default&gt;
    &lt;i class=&quot;el-icon-plus&quot;&gt;&lt;/i&gt;
  &lt;/template&gt;
  &lt;template #file=&quot;{file}&quot;&gt;
    &lt;img class=&quot;el-upload-list__item-thumbnail&quot; :src=&quot;file.url&quot; alt=&quot;&quot; /&gt;
    &lt;span class=&quot;el-upload-list__item-actions&quot;&gt;
      &lt;span
        class=&quot;el-upload-list__item-preview&quot;
        @click=&quot;handlePictureCardPreview(file)&quot;
      &gt;
        &lt;i class=&quot;el-icon-zoom-in&quot;&gt;&lt;/i&gt;
      &lt;/span&gt;
      &lt;span class=&quot;el-upload-list__item-delete&quot; @click=&quot;handleDownload(file)&quot;&gt;
        &lt;i class=&quot;el-icon-download&quot;&gt;&lt;/i&gt;
      &lt;/span&gt;
      &lt;span class=&quot;el-upload-list__item-delete&quot; @click=&quot;handleRemove(file)&quot;&gt;
        &lt;i class=&quot;el-icon-delete&quot;&gt;&lt;/i&gt;
      &lt;/span&gt;
    &lt;/span&gt;
  &lt;/template&gt;
&lt;/el-upload&gt;
&lt;el-dialog :visible.sync=&quot;dialogVisible&quot; v-model:visible=&quot;dialogVisible&quot;&gt;
  &lt;img width=&quot;100%&quot; :src=&quot;dialogImageUrl&quot; style=&quot;width: 100%&quot; /&gt;
&lt;/el-dialog&gt;
&lt;script&gt;
  import { ref, unref } from 'vue'

  export default {
    setup() {
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)
      const uploadRef = ref(null)

      const handleRemove = (file) =&gt; {
        console.log('remove')
        uploadRef.value.handleRemove(file)
      }

      const handlePictureCardPreview = (file) =&gt; {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      const handleDownload = (file) =&gt; {
        console.log('DownLoad')
      }

      return {
        dialogImageUrl,
        dialogVisible,
        uploadRef,
        handleRemove,
        handlePictureCardPreview,
        handleDownload
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>图片列表缩略图</h3>
<p>上传图片文件, 点击已上传的图片列表可查看缩略图</p>
<demo-block>
        
        <template #source><element-demo4 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  :on-preview=&quot;handlePreview&quot;
  :on-remove=&quot;handleRemove&quot;
  :file-list=&quot;fileList&quot;
  list-type=&quot;picture&quot;
&gt;
  &lt;el-button size=&quot;small&quot; type=&quot;primary&quot;&gt;选择文件&lt;/el-button&gt;
&lt;/el-upload&gt;
&lt;el-dialog :visible.sync=&quot;dialogVisible&quot; v-model:visible=&quot;dialogVisible&quot;&gt;
  &lt;img width=&quot;100%&quot; :src=&quot;dialogImageUrl&quot; style=&quot;width: 100%&quot; /&gt;
&lt;/el-dialog&gt;
&lt;script&gt;
  import { reactive, ref, unref, toRefs } from 'vue'

  export default {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)

      const handleRemove = (file, fileList) =&gt; {
        console.log(file, fileList)
      }

      const handlePreview = (file) =&gt; {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      return {
        ...toRefs(state),
        dialogVisible,
        dialogImageUrl,
        handleRemove,
        handlePreview
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>上传文件列表控制</h3>
<p>上传文件之前、上传成功、失败都触发 <code>on-change</code> 钩子, 可通过该钩子函数来对列表进行控制,</p>
<demo-block>
        
        <template #source><element-demo5 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  :on-change=&quot;handleChange&quot;
  :file-list=&quot;fileList&quot;
&gt;
  &lt;el-button size=&quot;small&quot; type=&quot;primary&quot;&gt;点击上传&lt;/el-button&gt;
&lt;/el-upload&gt;
&lt;script&gt;
  import { reactive, toRefs } from 'vue'

  export default {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })

      const handleChange = (file, fileList) =&gt; {
        console.log(file)
        console.log(fileList)
      }

      return {
        ...toRefs(state),
        handleChange
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>拖拽上传</h3>
<demo-block>
        
        <template #source><element-demo6 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;el-upload
  class=&quot;upload-demo&quot;
  drag
  action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
  multiple
&gt;
  &lt;i class=&quot;el-icon-upload&quot;&gt;&lt;/i&gt;
  &lt;div class=&quot;el-upload__text&quot;&gt;将文件拖到此处，或&lt;em&gt;点击上传&lt;/em&gt;&lt;/div&gt;
  &lt;div class=&quot;el-upload__tip&quot; slot=&quot;tip&quot;&gt;
    只能上传jpg/png文件，且不超过500kb
  &lt;/div&gt;
&lt;/el-upload&gt;
</code></pre></template></demo-block><h3>手动上传</h3>
<p>可控制已选择文件列表上传的时机，调用 <code>upload</code> 实例的 <code>submit</code> 方法</p>
<demo-block>
        
        <template #source><element-demo7 /></template>
        <template #highlight><pre v-pre><code class="html">&lt;template&gt;
  &lt;el-upload
    ref=&quot;upload&quot;
    action=&quot;https://jsonplaceholder.typicode.com/posts/&quot;
    :on-preview=&quot;handlePreview&quot;
    :on-remove=&quot;handleRemove&quot;
    :file-list=&quot;fileList&quot;
    :auto-upload=&quot;false&quot;
  &gt;
    &lt;template v-slot:trigger&gt;
      &lt;el-button size=&quot;small&quot; type=&quot;primary&quot;&gt;选取文件&lt;/el-button&gt;
    &lt;/template&gt;
    &lt;el-button
      style=&quot;margin-left: 10px;&quot;
      size=&quot;small&quot;
      type=&quot;success&quot;
      @click=&quot;submitUpload&quot;
      &gt;开始上传至服务器&lt;/el-button
    &gt;
  &lt;/el-upload&gt;
&lt;/template&gt;
&lt;script&gt;
  import { reactive, ref, toRefs } from 'vue'

  export default {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })

      const upload = ref(null)

      const submitUpload = () =&gt; {
        upload.value.submit()
      }
      const handleRemove = (file, fileList) =&gt; {
        console.log(file, fileList)
      }
      const handlePreview = (file) =&gt; {
        console.log(file)
      }

      return {
        ...toRefs(state),
        submitUpload,
        handleRemove,
        handlePreview,
        upload
      }
    }
  }
&lt;/script&gt;
</code></pre></template></demo-block><h3>Attribute</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>action</td>
<td>必选参数，上传的地址</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>headers</td>
<td>设置上传的请求头部</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>multiple</td>
<td>是否支持多选文件</td>
<td>boolean</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>data</td>
<td>上传时附带的额外参数</td>
<td>object</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>name</td>
<td>上传的文件字段名</td>
<td>string</td>
<td>—</td>
<td>file</td>
</tr>
<tr>
<td>with-credentials</td>
<td>支持发送 cookie 凭证信息</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>show-file-list</td>
<td>是否显示已上传文件列表</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>drag</td>
<td>是否启用拖拽上传</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>accept</td>
<td>接受上传的<a href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input#attr-accept">文件类型</a>（thumbnail-mode 模式下此参数无效）</td>
<td>string</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-preview</td>
<td>点击文件列表中已上传的文件时的钩子</td>
<td>function(file)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-remove</td>
<td>文件列表移除文件时的钩子</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-success</td>
<td>文件上传成功时的钩子</td>
<td>function(response, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-error</td>
<td>文件上传失败时的钩子</td>
<td>function(err, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-progress</td>
<td>文件上传时的钩子</td>
<td>function(event, file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-change</td>
<td>文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>before-upload</td>
<td>上传文件之前的钩子，参数为上传的文件，若返回 false 或者返回 Promise 且被 reject，则停止上传。</td>
<td>function(file)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>before-remove</td>
<td>删除文件之前的钩子，参数为上传的文件和文件列表，若返回 false 或者返回 Promise 且被 reject，则停止删除。</td>
<td>function(file, fileList)</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>list-type</td>
<td>文件列表的类型</td>
<td>string</td>
<td>text/picture/picture-card</td>
<td>text</td>
</tr>
<tr>
<td>auto-upload</td>
<td>是否在选取文件后立即进行上传</td>
<td>boolean</td>
<td>—</td>
<td>true</td>
</tr>
<tr>
<td>file-list</td>
<td>上传的文件列表, 例如: [{name: 'food.jpg', url: 'https://xxx.cdn.com/xxx.jpg'}]</td>
<td>array</td>
<td>—</td>
<td>[]</td>
</tr>
<tr>
<td>http-request</td>
<td>覆盖默认的上传行为，可以自定义上传的实现</td>
<td>function</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>disabled</td>
<td>是否禁用</td>
<td>boolean</td>
<td>—</td>
<td>false</td>
</tr>
<tr>
<td>limit</td>
<td>最大允许上传个数</td>
<td>number</td>
<td>—</td>
<td>—</td>
</tr>
<tr>
<td>on-exceed</td>
<td>文件超出个数限制时的钩子</td>
<td>function(files, fileList)</td>
<td>—</td>
<td>-</td>
</tr>
</tbody>
</table>
<h3>Slot</h3>
<table>
<thead>
<tr>
<th>name</th>
<th>说明</th>
</tr>
</thead>
<tbody>
<tr>
<td>trigger</td>
<td>触发文件选择框的内容</td>
</tr>
<tr>
<td>tip</td>
<td>提示说明文字</td>
</tr>
</tbody>
</table>
<h3>Methods</h3>
<table>
<thead>
<tr>
<th>方法名</th>
<th>说明</th>
<th>参数</th>
</tr>
</thead>
<tbody>
<tr>
<td>clearFiles</td>
<td>清空已上传的文件列表（该方法不支持在 before-upload 中调用）</td>
<td>—</td>
</tr>
<tr>
<td>abort</td>
<td>取消上传请求</td>
<td>（ file: fileList 中的 file 对象 ）</td>
</tr>
<tr>
<td>submit</td>
<td>手动上传文件列表</td>
<td>—</td>
</tr>
</tbody>
</table>

      </section>
    </template>
    <script>
      import hljs from 'highlight.js'
      import * as Vue from "vue"
      export default {
        name: 'component-doc',
        components: {
          "element-demo0": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "upload-demo",
    action: "https://jsonplaceholder.typicode.com/posts/",
    "on-preview": _ctx.handlePreview,
    "on-remove": _ctx.handleRemove,
    "before-remove": _ctx.beforeRemove,
    multiple: "",
    limit: 3,
    "on-exceed": _ctx.handleExceed,
    "file-list": _ctx.fileList
  }, {
    tip: _withCtx(() => [
      _createVNode("div", { class: "el-upload__tip" }, "只能上传jpg/png文件，且不超过500kb")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        size: "small",
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("点击上传")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["on-preview", "on-remove", "before-remove", "on-exceed", "file-list"]))
}
  
    
        const { reactive, toRefs, getCurrentInstance } = Vue
        
  const democomponentExport = {
    setup() {
      let state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })
      const self = getCurrentInstance().ctx
      const handleRemove = (file, fileList) => {
        console.log(file, fileList)
      }
      const handlePreview = (file) => {
        console.log(file)
      }
      const handleExceed = (files, fileList) => {
        self.$message.warning(
          `当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
            files.length + fileList.length
          } 个文件`
        )
      }
      const beforeRemove = (file, fileList) => {
        // return self.$confirm(`确定移除 ${ file.name }？`);
      }

      return {
        ...toRefs(state),
        handleRemove,
        handlePreview,
        handleExceed,
        beforeRemove
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo1": (function() {
    
    const { createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock, createCommentVNode: _createCommentVNode, resolveComponent: _resolveComponent, withCtx: _withCtx } = Vue

function render (_ctx, _cache) {
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "avatar-uploader",
    action: "https://jsonplaceholder.typicode.com/posts/",
    "show-file-list": false,
    "on-success": _ctx.handleAvatarSuccess,
    "before-upload": _ctx.beforeAvatarUpload
  }, {
    default: _withCtx(() => [
      (_ctx.imageUrl)
        ? (_openBlock(), _createBlock("img", {
            key: 0,
            src: _ctx.imageUrl,
            class: "avatar"
          }, null, 8, ["src"]))
        : (_openBlock(), _createBlock("i", {
            key: 1,
            class: "el-icon-plus avatar-uploader-icon"
          }))
    ]),
    _: 1
  }, 8, ["on-success", "before-upload"]))
}
  
    
        const { ref } = Vue
        
  
            const { Message } = Element3
          
  const democomponentExport = {
    setup() {
      const imageUrl = ref('')

      const handleAvatarSuccess = (res, file) => {
        imageUrl.value = URL.createObjectURL(file.raw)
      }
      const beforeAvatarUpload = (file) => {
        const isJPG = file.type === 'image/jpeg'
        const isLt2M = file.size / 1024 / 1024 < 2

        if (!isJPG) {
          Message.error('上传头像图片只能是 JPG 格式!')
        }
        if (!isLt2M) {
          Message.error('上传头像图片大小不能超过 2MB!')
        }
        return isJPG && isLt2M
      }

      return {
        imageUrl,
        handleAvatarSuccess,
        beforeAvatarUpload
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo2": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      action: "https://jsonplaceholder.typicode.com/posts/",
      "list-type": "picture-card",
      "on-preview": _ctx.handlePictureCardPreview,
      "on-remove": _ctx.handleRemove
    }, {
      default: _withCtx(() => [
        _createVNode("i", { class: "el-icon-plus" })
      ]),
      _: 1
    }, 8, ["on-preview", "on-remove"]),
    _createVNode(_component_el_dialog, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": $event => (_ctx.dialogVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode("img", {
          src: _ctx.dialogImageUrl,
          alt: "",
          style: {"width":"100%"}
        }, null, 8, ["src"])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { ref, unref } = Vue
        
  const democomponentExport = {
    setup() {
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)

      const handleRemove = (file, fileList) => {
        console.log(file, fileList)
      }
      const handlePictureCardPreview = (file) => {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      return {
        dialogImageUrl,
        dialogVisible,
        handleRemove,
        handlePictureCardPreview
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo3": (function() {
    
    const { createVNode: _createVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      action: "https://jsonplaceholder.typicode.com/posts/",
      "list-type": "picture-card",
      ref: "uploadRef",
      "auto-upload": false
    }, {
      default: _withCtx(() => [
        _createVNode("i", { class: "el-icon-plus" })
      ]),
      file: _withCtx(({file}) => [
        _createVNode("img", {
          class: "el-upload-list__item-thumbnail",
          src: file.url,
          alt: ""
        }, null, 8, ["src"]),
        _createVNode("span", { class: "el-upload-list__item-actions" }, [
          _createVNode("span", {
            class: "el-upload-list__item-preview",
            onClick: $event => (_ctx.handlePictureCardPreview(file))
          }, [
            _createVNode("i", { class: "el-icon-zoom-in" })
          ], 8, ["onClick"]),
          _createVNode("span", {
            class: "el-upload-list__item-delete",
            onClick: $event => (_ctx.handleDownload(file))
          }, [
            _createVNode("i", { class: "el-icon-download" })
          ], 8, ["onClick"]),
          _createVNode("span", {
            class: "el-upload-list__item-delete",
            onClick: $event => (_ctx.handleRemove(file))
          }, [
            _createVNode("i", { class: "el-icon-delete" })
          ], 8, ["onClick"])
        ])
      ]),
      _: 1
    }, 512),
    _createVNode(_component_el_dialog, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": $event => (_ctx.dialogVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode("img", {
          width: "100%",
          src: _ctx.dialogImageUrl,
          style: {"width":"100%"}
        }, null, 8, ["src"])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { ref, unref } = Vue
        

  const democomponentExport = {
    setup() {
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)
      const uploadRef = ref(null)

      const handleRemove = (file) => {
        console.log('remove')
        uploadRef.value.handleRemove(file)
      }

      const handlePictureCardPreview = (file) => {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      const handleDownload = (file) => {
        console.log('DownLoad')
      }

      return {
        dialogImageUrl,
        dialogVisible,
        uploadRef,
        handleRemove,
        handlePictureCardPreview,
        handleDownload
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo4": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, Fragment: _Fragment, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_upload = _resolveComponent("el-upload")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_el_upload, {
      action: "https://jsonplaceholder.typicode.com/posts/",
      "on-preview": _ctx.handlePreview,
      "on-remove": _ctx.handleRemove,
      "file-list": _ctx.fileList,
      "list-type": "picture"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          size: "small",
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("选择文件")
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["on-preview", "on-remove", "file-list"]),
    _createVNode(_component_el_dialog, {
      visible: _ctx.dialogVisible,
      "onUpdate:visible": $event => (_ctx.dialogVisible = $event)
    }, {
      default: _withCtx(() => [
        _createVNode("img", {
          width: "100%",
          src: _ctx.dialogImageUrl,
          style: {"width":"100%"}
        }, null, 8, ["src"])
      ]),
      _: 1
    }, 8, ["visible", "onUpdate:visible"])
  ], 64))
}
  
    
        const { reactive, ref, unref, toRefs } = Vue
        

  const democomponentExport = {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })
      const dialogImageUrl = ref('')
      const dialogVisible = ref(false)

      const handleRemove = (file, fileList) => {
        console.log(file, fileList)
      }

      const handlePreview = (file) => {
        dialogImageUrl.value = unref(file).url
        dialogVisible.value = true
      }

      return {
        ...toRefs(state),
        dialogVisible,
        dialogImageUrl,
        handleRemove,
        handlePreview
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo5": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    action: "https://jsonplaceholder.typicode.com/posts/",
    "on-change": _ctx.handleChange,
    "file-list": _ctx.fileList
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        size: "small",
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("点击上传")
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["on-change", "file-list"]))
}
  
    
        const { reactive, toRefs } = Vue
        

  const democomponentExport = {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })

      const handleChange = (file, fileList) => {
        console.log(file)
        console.log(fileList)
      }

      return {
        ...toRefs(state),
        handleChange
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo6": (function() {
    
    const { createVNode: _createVNode, createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    class: "upload-demo",
    drag: "",
    action: "https://jsonplaceholder.typicode.com/posts/",
    multiple: ""
  }, {
    default: _withCtx(() => [
      _createVNode("i", { class: "el-icon-upload" }),
      _createVNode("div", { class: "el-upload__text" }, [
        _createTextVNode("将文件拖到此处，或"),
        _createVNode("em", null, "点击上传")
      ]),
      _createVNode("div", {
        class: "el-upload__tip",
        slot: "tip"
      }, " 只能上传jpg/png文件，且不超过500kb ")
    ]),
    _: 1
  }))
}
  
    const democomponentExport = {}
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),"element-demo7": (function() {
    
    const { createTextVNode: _createTextVNode, resolveComponent: _resolveComponent, withCtx: _withCtx, createVNode: _createVNode, openBlock: _openBlock, createBlock: _createBlock } = Vue

function render (_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_upload = _resolveComponent("el-upload")

  return (_openBlock(), _createBlock(_component_el_upload, {
    ref: "upload",
    action: "https://jsonplaceholder.typicode.com/posts/",
    "on-preview": _ctx.handlePreview,
    "on-remove": _ctx.handleRemove,
    "file-list": _ctx.fileList,
    "auto-upload": false
  }, {
    trigger: _withCtx(() => [
      _createVNode(_component_el_button, {
        size: "small",
        type: "primary"
      }, {
        default: _withCtx(() => [
          _createTextVNode("选取文件")
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_button, {
        style: {"margin-left":"10px"},
        size: "small",
        type: "success",
        onClick: _ctx.submitUpload
      }, {
        default: _withCtx(() => [
          _createTextVNode("开始上传至服务器")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _: 1
  }, 8, ["on-preview", "on-remove", "file-list"]))
}
  
    
        const { reactive, ref, toRefs } = Vue
        

  const democomponentExport = {
    setup() {
      const state = reactive({
        fileList: [
          {
            name: 'food.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          },
          {
            name: 'food2.jpeg',
            url:
              'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
          }
        ]
      })

      const upload = ref(null)

      const submitUpload = () => {
        upload.value.submit()
      }
      const handleRemove = (file, fileList) => {
        console.log(file, fileList)
      }
      const handlePreview = (file) => {
        console.log(file)
      }

      return {
        ...toRefs(state),
        submitUpload,
        handleRemove,
        handlePreview,
        upload
      }
    }
  }
    return {
      mounted(){
        this.$nextTick(()=>{
          const blocks = document.querySelectorAll('pre code:not(.hljs)')
          Array.prototype.forEach.call(blocks, hljs.highlightBlock)
        })
      },
      render,
      ...democomponentExport
    }
  })(),
        }
      }
    </script>
  